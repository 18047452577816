import styled, { css } from "styled-components";
import { SvgElement } from "@paciolan/map-components/dist/SvgElement";
import { availabilityPercentageLookup } from "components/AvailabilityLegend/styled";
const getAvailabilityFill = percent => {
  if (percent === 0) {
    return _get__("availabilityPercentageLookup")[0];
  }
  const quartile = Math.floor(percent / 25);
  return _get__("availabilityPercentageLookup")[quartile + 1];
};
export const SectionNameSvg = _get__("styled")(_get__("SvgElement"))`
  pointer-events: none;
  user-select: none;
  font-size: ${_ref => {
  let {
    metadata
  } = _ref;
  return metadata.secTxtFont || 60;
}}px;
  fill: ${_ref2 => {
  let {
    theme,
    $showSeatDots,
    isLoading
  } = _ref2;
  return $showSeatDots && !isLoading ? theme.color?.gray?.two : theme.color?.black || "#000";
}};
  font-weight: 400;
  text-anchor: middle;
  transform: scale(${_ref3 => {
  let {
    scale
  } = _ref3;
  return scale;
}})
    translate(${_ref4 => {
  let {
    tx
  } = _ref4;
  return tx;
}}px, ${_ref5 => {
  let {
    ty
  } = _ref5;
  return ty;
}}px)
    rotate(${_ref6 => {
  let {
    rotation
  } = _ref6;
  return rotation;
}}deg);
`;
const sectionNameHoverFill = `
& + ${_get__("SectionNameSvg")} {
  fill: white;
}`;
const DisabledUnselectedMixin = _get__("css")`
  fill: ${_ref7 => {
  let {
    theme
  } = _ref7;
  return theme.color?.gray?.one;
}};
  stroke: #c2c5ca;
  stroke-width: 5px;
`;
export const DisabledMixin = _get__("css")`
  cursor: auto;
  pointer-events: ${_ref8 => {
  let {
    $showSeatDots
  } = _ref8;
  return $showSeatDots ? "none" : "visiblepainted";
}};
  ${_ref9 => {
  let {
    selected
  } = _ref9;
  return selected ? "" : _get__("DisabledUnselectedMixin");
}};
`;
export const SectionButtonSvg = _get__("styled")(_get__("SvgElement"))`
  fill: ${_ref10 => {
  let {
    selected,
    percent,
    theme
  } = _ref10;
  if (selected) return theme.color?.black;
  return _get__("getAvailabilityFill")(percent);
}};
  fill-opacity: 1;
  cursor: pointer;
  pointer-events: visiblepainted;
  stroke: ${_ref11 => {
  let {
    theme
  } = _ref11;
  return theme.color?.gray?.seven;
}};
  transform: scale(${_ref12 => {
  let {
    scale
  } = _ref12;
  return scale;
}});

  ${_ref13 => {
  let {
    selected
  } = _ref13;
  return selected ? _get__("sectionNameHoverFill") : "";
}};
  ${_ref14 => {
  let {
    disabled,
    $showSeatDots
  } = _ref14;
  return (disabled || $showSeatDots) && _get__("DisabledMixin");
}};

  @media (hover: hover) {
    &:hover {
      ${_ref15 => {
  let {
    disabled,
    theme
  } = _ref15;
  if (disabled) return;
  return `
        fill: ${theme.color?.gray?.seven};
         ${_get__("sectionNameHoverFill")}
        `;
}}
  }
`;
function _getGlobalObject() {
  try {
    if (!!global) {
      return global;
    }
  } catch (e) {
    try {
      if (!!window) {
        return window;
      }
    } catch (e) {
      return this;
    }
  }
}
;
var _RewireModuleId__ = null;
function _getRewireModuleId__() {
  if (_RewireModuleId__ === null) {
    let globalVariable = _getGlobalObject();
    if (!globalVariable.__$$GLOBAL_REWIRE_NEXT_MODULE_ID__) {
      globalVariable.__$$GLOBAL_REWIRE_NEXT_MODULE_ID__ = 0;
    }
    _RewireModuleId__ = __$$GLOBAL_REWIRE_NEXT_MODULE_ID__++;
  }
  return _RewireModuleId__;
}
function _getRewireRegistry__() {
  let theGlobalVariable = _getGlobalObject();
  if (!theGlobalVariable.__$$GLOBAL_REWIRE_REGISTRY__) {
    theGlobalVariable.__$$GLOBAL_REWIRE_REGISTRY__ = Object.create(null);
  }
  return theGlobalVariable.__$$GLOBAL_REWIRE_REGISTRY__;
}
function _getRewiredData__() {
  let moduleId = _getRewireModuleId__();
  let registry = _getRewireRegistry__();
  let rewireData = registry[moduleId];
  if (!rewireData) {
    registry[moduleId] = Object.create(null);
    rewireData = registry[moduleId];
  }
  return rewireData;
}
(function registerResetAll() {
  let theGlobalVariable = _getGlobalObject();
  if (!theGlobalVariable['__rewire_reset_all__']) {
    theGlobalVariable['__rewire_reset_all__'] = function () {
      theGlobalVariable.__$$GLOBAL_REWIRE_REGISTRY__ = Object.create(null);
    };
  }
})();
var INTENTIONAL_UNDEFINED = '__INTENTIONAL_UNDEFINED__';
let _RewireAPI__ = {};
(function () {
  function addPropertyToAPIObject(name, value) {
    Object.defineProperty(_RewireAPI__, name, {
      value: value,
      enumerable: false,
      configurable: true
    });
  }
  addPropertyToAPIObject('__get__', _get__);
  addPropertyToAPIObject('__GetDependency__', _get__);
  addPropertyToAPIObject('__Rewire__', _set__);
  addPropertyToAPIObject('__set__', _set__);
  addPropertyToAPIObject('__reset__', _reset__);
  addPropertyToAPIObject('__ResetDependency__', _reset__);
  addPropertyToAPIObject('__with__', _with__);
})();
function _get__(variableName) {
  let rewireData = _getRewiredData__();
  if (rewireData[variableName] === undefined) {
    return _get_original__(variableName);
  } else {
    var value = rewireData[variableName];
    if (value === INTENTIONAL_UNDEFINED) {
      return undefined;
    } else {
      return value;
    }
  }
}
function _get_original__(variableName) {
  switch (variableName) {
    case "availabilityPercentageLookup":
      return availabilityPercentageLookup;
    case "styled":
      return styled;
    case "SvgElement":
      return SvgElement;
    case "SectionNameSvg":
      return SectionNameSvg;
    case "css":
      return css;
    case "DisabledUnselectedMixin":
      return DisabledUnselectedMixin;
    case "getAvailabilityFill":
      return getAvailabilityFill;
    case "sectionNameHoverFill":
      return sectionNameHoverFill;
    case "DisabledMixin":
      return DisabledMixin;
  }
  return undefined;
}
function _assign__(variableName, value) {
  let rewireData = _getRewiredData__();
  if (rewireData[variableName] === undefined) {
    return _set_original__(variableName, value);
  } else {
    return rewireData[variableName] = value;
  }
}
function _set_original__(variableName, _value) {
  switch (variableName) {}
  return undefined;
}
function _update_operation__(operation, variableName, prefix) {
  var oldValue = _get__(variableName);
  var newValue = operation === '++' ? oldValue + 1 : oldValue - 1;
  _assign__(variableName, newValue);
  return prefix ? newValue : oldValue;
}
function _set__(variableName, value) {
  let rewireData = _getRewiredData__();
  if (typeof variableName === 'object') {
    Object.keys(variableName).forEach(function (name) {
      rewireData[name] = variableName[name];
    });
    return function () {
      Object.keys(variableName).forEach(function (name) {
        _reset__(variableName);
      });
    };
  } else {
    if (value === undefined) {
      rewireData[variableName] = INTENTIONAL_UNDEFINED;
    } else {
      rewireData[variableName] = value;
    }
    return function () {
      _reset__(variableName);
    };
  }
}
function _reset__(variableName) {
  let rewireData = _getRewiredData__();
  delete rewireData[variableName];
  if (Object.keys(rewireData).length == 0) {
    delete _getRewireRegistry__()[_getRewireModuleId__];
  }
  ;
}
function _with__(object) {
  let rewireData = _getRewiredData__();
  var rewiredVariableNames = Object.keys(object);
  var previousValues = {};
  function reset() {
    rewiredVariableNames.forEach(function (variableName) {
      rewireData[variableName] = previousValues[variableName];
    });
  }
  return function (callback) {
    rewiredVariableNames.forEach(function (variableName) {
      previousValues[variableName] = rewireData[variableName];
      rewireData[variableName] = object[variableName];
    });
    let result = callback();
    if (!!result && typeof result.then == 'function') {
      result.then(reset).catch(reset);
    } else {
      reset();
    }
    return result;
  };
}
export { _get__ as __get__, _get__ as __GetDependency__, _set__ as __Rewire__, _set__ as __set__, _reset__ as __ResetDependency__, _RewireAPI__ as __RewireAPI__ };
export default _RewireAPI__;